












































































































































import { Component, Vue } from 'vue-property-decorator';
import FlagshipGoalsWizardPersonalGoalViewModel
  from '@/vue-app/view-models/components/flagship/flagship-goals/flagship-goals-wizard-personal-goal-view-model';

@Component({ name: 'FlagshipGoalsWizardPersonalGoal' })
export default class FlagshipGoalsWizardPersonalGoal extends Vue {
  flagship_personal_goal_model = Vue.observable(new FlagshipGoalsWizardPersonalGoalViewModel(this));

  created() {
    this.flagship_personal_goal_model.initialize();
  }
}
