import Vue from 'vue';
import TYPES from '@/types';

// Application
import GetCustomGoalTypesQuery
  from '@/modules/flagship/catalogs/custom-goal-types/application/queries/get-custom-goal-types-query';
import GetInvestorProfileQuery
  from '@/modules/flagship/investor-profile/investor-profile/application/queries/get-investor-profile-query';
import UpdateInvestorProfileCommand
  from '@/modules/flagship/investor-profile/investor-profile/application/commands/update-investor-profile-command';

// Domain
import {
  CustomGoalTypesEntity,
} from '@/modules/flagship/catalogs/custom-goal-types/domain/entities/custom-goal-types-entity';
import {
  InvestorProfileDto,
} from '@/modules/flagship/investor-profile/investor-profile/domain/dtos/investor-profile-dto';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { maxLengthGoalNameRule, requiredRule } from '@/vue-app/utils/form-rules';

export default class FlagshipGoalsWizardPersonalGoalViewModel {
  @Inject(TYPES.GET_CUSTOM_GOAL_TYPES_QUERY)
  private readonly get_custom_goal_type!: GetCustomGoalTypesQuery;

  @Inject(TYPES.GET_INVESTOR_PROFILE_QUERY)
  private readonly get_investor_profile_query!: GetInvestorProfileQuery;

  @Inject(TYPES.UPDATE_INVESTOR_PROFILE_COMMAND)
  private readonly update_investor_profile_command!: UpdateInvestorProfileCommand;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  readonly i18n_namespace = 'components.flagship.flagship-goals.flagship_goals_wizard_personal_goal';

  readonly view: Vue;

  private investor_profile_id = '';

  private investor_profile_dto: InvestorProfileDto = {
    id: this.investor_profile_id,
    custom_goals: {},
  };

  custom_goal_types: Array<CustomGoalTypesEntity> = [];

  personal_goal = '';

  other_personal_goal = '';

  input_rules = {
    other_goal_name: [requiredRule, (value: string) => maxLengthGoalNameRule(26, value)],
  };

  is_loading = false;

  constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  get is_text_area_disabled() {
    return (this.personal_goal !== this.custom_goal_types[6]?.name);
  }

  get is_continue_btn_disabled() {
    if (this.personal_goal === this.custom_goal_types[6]?.name) {
      return !((this.personal_goal) && (this.other_personal_goal))
        || !(this.other_personal_goal.length < 26) || this.is_loading;
    }

    return !(this.personal_goal) || this.is_loading;
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  setCustomGoal = () => {
    const custom_goal_value = (this.custom_goal_types.find(
      (item) => item.name === this.personal_goal,
    ));

    // if the user selects the 'other' option saves the text the user entered
    if (this.personal_goal === this.custom_goal_types[6]?.name) {
      custom_goal_value!.label = this.other_personal_goal;
    }

    this.investor_profile_dto.custom_goals = {
      values: [
        custom_goal_value,
      ],
    };
  }

  getCustomGoalTypes = async () => {
    try {
      this.custom_goal_types = await this.get_custom_goal_type.execute();
    } catch (e) {
      this.message_notifier.showErrorNotification(this.translate('errors.get_custom_goal_types'));
    }
  };

  getInvestorProfile = async () => {
    try {
      const investor_profile = await this.get_investor_profile_query.execute(false);
      this.investor_profile_id = investor_profile.id;
      const custom_goals = investor_profile.custom_goals.values;
      if (custom_goals.length > 0) {
        this.personal_goal = custom_goals[0].name;
        if (custom_goals[0].name === 'other') {
          this.other_personal_goal = custom_goals[0].label;
        }
      }
      return true;
    } catch {
      return false;
    }
  };

  initialize = async () => {
    this.is_loading = true;
    this.view.$emit('loadingStep', true);
    await this.getCustomGoalTypes();
    await this.getInvestorProfile();
    this.view.$emit('loadingStep', false);
    this.is_loading = false;
  }

  updateInvestorProfile = async () => {
    try {
      this.investor_profile_dto.id = this.investor_profile_id;
      await this.update_investor_profile_command.execute(this.investor_profile_dto);
      return true;
    } catch (e) {
      this.message_notifier.showErrorNotification(this.translate('errors.update_investor_profile'));
      return false;
    }
  };

  onSubmit = async () => {
    this.is_loading = true;
    this.view.$emit('loadingStep', true);
    this.setCustomGoal();
    const stored = await this.updateInvestorProfile();
    this.view.$emit('loadingStep', false);
    this.is_loading = false;
    if (stored) {
      this.view.$emit('nextStep');
    }
  }
}
